import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import commingsoon from "@/assets/img/common/comingsoon.png";

import "./nft.less";
import opass from "@/assets/img/opass.png";
import opx from "@/assets/img/opx.png";
import opt from "@/assets/img/opt.png";

import {useAccount} from "wagmi";
import {createPublicClient, http} from "viem";
import {bsc} from "wagmi/chains";
import {usdtTokenContract} from "../../hooks/usdtTokenContract";
import {OPASS_NFT, OPL_TOKEN, OPT_NFT, OPX_NFT, USDT_TOKEN} from "../../utils/constants";
import {getBalanceNumber, toBigNumber, toBigString} from "../../utils/formatBalance";
import BigNumber from "bignumber.js";
import {OPLContract} from "../../abis/OPLContract";
import {OPASSContract} from "../../abis/OPASSContract";
import {OPXContract} from "../../abis/OPXContract";

import useInterval from "../../hooks/useInterval";
import {useOPLDeposit} from "../../hooks/useOPL";
import {useNFT} from "../../hooks/useNFT";
import useTokenApprove from "../../hooks/useTokenApprove";
import {message} from "antd";


const Nft = () => {
    const { t } = useTranslation();
    const { address } = useAccount();
    const formatToUTC8 = (timestamp:any) => {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours() + 8).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes} UTC+8`;
    };

    const publicClient = createPublicClient({ chain: bsc, transport: http() });

    const [usdtAllowance, setUsdtAllowance] = useState(0);
    const [oplAllowance, setOplAllowance] = useState(0);

    const fetchSellNftList = useCallback(async () => {
        try {
            const baseAllowance = await publicClient.readContract({
                ...usdtTokenContract,
                address: USDT_TOKEN,
                functionName: "allowance",
                args: [address as `0x${string}`, OPX_NFT as `0x${string}`],
            });
            const baseAllowanceValue = getBalanceNumber(
                new BigNumber(baseAllowance.toString())
            );
            setUsdtAllowance(baseAllowanceValue);
            const oplAllowance = await publicClient.readContract({
                ...usdtTokenContract,
                address: USDT_TOKEN,
                functionName: "allowance",
                args: [address as `0x${string}`, OPT_NFT as `0x${string}`],
            });
            setOplAllowance(getBalanceNumber(
                new BigNumber(oplAllowance.toString())
            ));
        } catch (e) {
            console.error(e);
        }
    }, [
        address,
        OPL_TOKEN,
        USDT_TOKEN,
    ]);

    useInterval(fetchSellNftList);

    const [opassPrice, setOpassPrice] = useState(0);
    const [opassTime, setOpassTime] = useState('');
    const [isStart, setIsStart] = useState(false);
    const [opxPrice, setOpxPrice] = useState(0);
    const [isWhiteList,setIsWhiteList] = useState(false);

    const getNftData = async () => {
        const opassPrice = await publicClient.readContract({
            ...OPASSContract,
            address: OPT_NFT,
            functionName: "mintPrice",
            args: [],
        });
        setOpassPrice(getBalanceNumber(
            new BigNumber(opassPrice.toString())
        ));


        const opxPrice = await publicClient.readContract({
            ...OPXContract,
            address: OPX_NFT,
            functionName: "mintPrice",
            args: [],
        });

        setOpxPrice(getBalanceNumber(
            new BigNumber(opxPrice.toString())
        ));
    }

    useEffect(()=>{
        getNftData();
    },[address]);

    const {doOPASSMint,doOPXMint,doOPTMint} = useNFT(bsc);
    const { doApprove } = useTokenApprove(bsc);

    const mintOPASS = async () => {
        if (!address) {
            return message.error({
                content: 'Please Connect Wallet',
            });
        }
        if (oplAllowance < opassPrice) {
            message.error({
                content: 'Please Approve',
            });
            await doApprove(USDT_TOKEN, OPT_NFT,opassPrice);
            return;
        }
        await doOPTMint();
    }

    const mintOPX = async () => {
        if (!address) {
            return message.error({
                content: 'Please Connect Wallet',
            });
        }
        if (usdtAllowance < opxPrice) {
            message.error({
                content: 'Please Approve',
            });
            await doApprove(USDT_TOKEN, OPX_NFT,opxPrice);
            return;
        }
        await doOPXMint();
    }

    const mintOPASSWhiteList = async () => {
        if (!address) {
            return message.error({
                content: 'Please Connect Wallet',
            });
        }
        await doOPASSMint();
    }

    return (
    <div className="root">
        <div className="container">
            <div className="nftpage-container">
                <h1 className="nftpage-title">NFT Mint</h1>
                <div className="nftpage-cards">
                    <div  className="nftpage-card">
                        <img src={opt}  className="nftpage-card-image" />
                        <h2 className="nftpage-card-title">OPT</h2>
                        <p className="nftpage-card-description"></p>
                        <div className="nftpage-card-price">
                            {opassPrice} USDT
                        </div>
                        <div className="nftpage-card-presale">
                            Status: On sale
                        </div>
                        <button
                            className="nftpage-card-button"
                            disabled={false}
                            onClick={mintOPASS}
                        >
                            Mint Now
                        </button>
                       {/* {
                            isWhiteList?<button
                                className="nftpage-card-button"
                                disabled={false}
                                onClick={mintOPASSWhiteList}
                                style={{marginTop:20}}
                            >
                                Mint For WhiteList
                            </button>:<div/>
                        }*/}
                    </div>

                    <div  className="nftpage-card">
                        <img src={opx}  className="nftpage-card-image" />
                        <h2 className="nftpage-card-title">OPX</h2>
                        <p className="nftpage-card-description"></p>
                        <div className="nftpage-card-price">
                            {opxPrice} USDT
                        </div>
                        <div className="nftpage-card-presale">
                            Status: On sale
                        </div>
                        <button
                            className="nftpage-card-button"
                            disabled={false}
                            onClick={mintOPX}
                        >
                            Mint Now
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
};

export default Nft;
