import {useCallback} from 'react';
import {createPublicClient, createWalletClient, custom, http} from "viem";
import {OPLContract} from "../abis/OPLContract";
import {OPL_TOKEN} from "../utils/constants";
import {Chain} from "viem/_types/types/chain";
import {toBigString} from "../utils/formatBalance";

export const useOPLDeposit = ( chain?: Chain | undefined) => {
    const publicClient = createPublicClient({chain: chain, transport: http(),});

    const handleDomainCommit = useCallback(async (amount: string) => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...OPLContract,
                    address: OPL_TOKEN,
                    functionName: 'deposit',
                    args: [BigInt(amount)],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
            }
        },
        [ publicClient, chain, ],
    );


    const handleDomainCommit2 = useCallback(async (amount: string) => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...OPLContract,
                    address: OPL_TOKEN,
                    functionName: 'withdraw',
                    args: [BigInt(amount)],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
            }
        },
        [ publicClient, chain, ],
    );

    return { doOPLDeposit : handleDomainCommit,doOPLWithdraw:handleDomainCommit2 };
};




