import {useCallback} from 'react';
import {createPublicClient, createWalletClient, custom, http} from "viem";
import {OPLContract} from "../abis/OPLContract";
import {OPASS_NFT, OPL_TOKEN, OPT_NFT, OPX_NFT} from "../utils/constants";
import {Chain} from "viem/_types/types/chain";
import {toBigString} from "../utils/formatBalance";
import {OPASSContract} from "../abis/OPASSContract";
import {OPXContract} from "../abis/OPXContract";
import {OPTContract} from "../abis/OPTContract";
import {message} from "antd";

export const useNFT = ( chain?: Chain | undefined) => {
    const publicClient = createPublicClient({chain: chain, transport: http(),});

    const handleDomainCommit = useCallback(async () => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...OPASSContract,
                    address: OPASS_NFT,
                    functionName: 'mint',
                    args: [],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
                message.error({
                    // @ts-ignore
                    content: e.toString(),
                });
            }
        },
        [ publicClient, chain, ],
    );


    const handleDomainCommit2 = useCallback(async () => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...OPXContract,
                    address: OPX_NFT,
                    functionName: 'mint',
                    args: [],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
                message.error({
                    // @ts-ignore
                    content: e.toString(),
                });
            }
        },
        [ publicClient, chain, ],
    );

    const handleDomainCommit3 = useCallback(async () => {
            try{
                const walletClient = createWalletClient({chain: chain, transport: custom(window.ethereum!),});
                const [address] = await walletClient.requestAddresses();
                const { request } = await publicClient.simulateContract({
                    ...OPTContract,
                    address: OPT_NFT,
                    functionName: 'mint',
                    args: [],
                    account: address
                });
                const hash = await walletClient.writeContract(request);
            }catch (e){
                console.error(e);
                message.error({
                    // @ts-ignore
                    content: e.toString(),
                });
            }
        },
        [ publicClient, chain, ],
    );

    return { doOPASSMint : handleDomainCommit,doOPXMint:handleDomainCommit2,doOPTMint:handleDomainCommit3 };
};




