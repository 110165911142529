import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import pdfIcon from "@/assets/img/common/pdf_icon.png";

import "./finance.less";
import Button from "../../Components/Button/Button";
import { MiniChart } from "react-ts-tradingview-widgets";
import InputWithButton from "../../Components/Input/InputWithButton";
import ButtonFlat from "../../Components/Button/ButtonFlat";
import {usdtTokenContract} from "../../hooks/usdtTokenContract";
import {OPL_TOKEN, SELL_MANAGER, USDT_TOKEN} from "../../utils/constants";
import {getBalanceNumber, toBigString} from "../../utils/formatBalance";
import BigNumber from "bignumber.js";
import useInterval from "../../hooks/useInterval";
import {createPublicClient, http} from "viem";
import {bsc} from "wagmi/chains";
import {useAccount} from "wagmi";
import {message} from "antd";
import useTokenApprove from "../../hooks/useTokenApprove";
import {useOPLDeposit} from "../../hooks/useOPL";
import {OPLContract} from "../../abis/OPLContract";


const Finance = () => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const [activeIndex,setActiveIndex] = useState(0);

  const logs = [
      {
          'name':'OpenAs financial statements PDF (2024.05)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.06)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.07)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.08)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.09)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.10)',
          'url':"",
      }
  ]


    const handleClickPancake = () => {
        window.open('https://pancakeswap.finance/', '_blank');
    };

    const publicClient = createPublicClient({ chain: bsc, transport: http() });

    const [usdtAllowance, setUsdtAllowance] = useState(0);
    const [usdtBalance, setUsdtBalance] = useState(0);

    const [oplAllowance, setOplAllowance] = useState(0);
    const [oplBalance, setOplBalance] = useState(0);
    const [opeReward, setOpeReward] = useState(0);

    const fetchSellNftList = useCallback(async () => {
        try {
            const baseAllowance = await publicClient.readContract({
                ...usdtTokenContract,
                address: USDT_TOKEN,
                functionName: "allowance",
                args: [address as `0x${string}`, OPL_TOKEN as `0x${string}`],
            });
            const baseAllowanceValue = getBalanceNumber(
                new BigNumber(baseAllowance.toString())
            );
            setUsdtAllowance(baseAllowanceValue);

            const usdtBalance = await publicClient.readContract({
                ...usdtTokenContract,
                address: USDT_TOKEN,
                functionName: "balanceOf",
                args: [address as `0x${string}`],
            });
            const usdtBalanceValue = getBalanceNumber(
                new BigNumber(usdtBalance.toString())
            );
            setUsdtBalance(usdtBalanceValue);


            const oplAllowance = await publicClient.readContract({
                ...OPLContract,
                address: OPL_TOKEN,
                functionName: "allowance",
                args: [address as `0x${string}`, OPL_TOKEN as `0x${string}`],
            });
            setOplAllowance(getBalanceNumber(
                new BigNumber(oplAllowance.toString())
            ));

            const oplBalance = await publicClient.readContract({
                ...OPLContract,
                address: OPL_TOKEN,
                functionName: "balanceOf",
                args: [address as `0x${string}`],
            });
            setOplBalance(getBalanceNumber(
                new BigNumber(oplBalance.toString())
            ));

        } catch (e) {
            console.error(e);
        }
    }, [
        setUsdtAllowance,
        setUsdtBalance,
        setOplAllowance,
        setOplBalance,
        setOpeReward,
        address,
        OPL_TOKEN,
        USDT_TOKEN,
    ]);

    useInterval(fetchSellNftList);

    const { doApprove } = useTokenApprove(bsc);
    const {doOPLDeposit,doOPLWithdraw} = useOPLDeposit(bsc);

    const [inputValue, setInputValue] = useState("");
    const buyOPL = async () =>{
        if (!address) {
            return message.error({
                content: 'Please Connect Wallet',
            });
        }
        if(activeIndex == 0){
            if (usdtBalance < parseFloat(inputValue)) {
                return message.error({
                    content: 'Insufficient balance',
                });
            }
            if (usdtAllowance < parseFloat(inputValue)) {
                message.error({
                    content: 'Please Approve',
                });
                await doApprove(USDT_TOKEN, OPL_TOKEN,inputValue);
                return;
            }
            await doOPLDeposit(toBigString(inputValue));
        }else{
            await doOPLWithdraw(toBigString(inputValue));
        }
    }


  return (
    <div className="root">
        <div className="container-finance">
            <div className="finance-title">{t('Finance')}</div>
            <div style={{marginTop:20,marginBottom:20}}>
                <Button style={{width:140}}>OPL</Button>
                <Button onClick={handleClickPancake} style={{width:140,background:'#eee',color:'#000',marginLeft:30}}>OPE</Button>
            </div>

            <div className="flex-container-finance">
                <div className="card-finance">
                    <MiniChart colorTheme="light" width="100%" chartOnly={false} height="100%"
                               symbol="BTCUSD"
                               trendLineColor="rgba(13,184,54,0.6)"
                                underLineColor="rgba(24,221,138,0.4)"
                                underLineBottomColor="rgba(224,240,38,0.2)"></MiniChart>
                    {/*<div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>OPL Dividends</div>
                    <div style={{marginBottom:10}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>
                            Current OPL
                        </div>
                        <div style={{width:'100%',height:60,display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div className="finance-amount">{oplBalance.toString()} OPL</div>
                        </div>
                    </div>
                    <div style={{marginBottom:10}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>
                            Current rewards
                        </div>
                        <div style={{width:'100%',height:60,display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div className="finance-amount"> OPE</div>
                        </div>
                    </div>
                    <Button style={{marginTop:10}}>Swap</Button>*/}
                </div>
                <div className="card2-finance">
                    <div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>{activeIndex==0?'Buy':'Sell'} OPL</div>
                    <div style={{marginTop:20,marginBottom:20}}>
                        {activeIndex == 1?
                            <div>
                                <Button onClick={()=>{setActiveIndex(0)}} style={{width:100,background:'#eee',color:'#000'}}>BUY</Button>
                                <Button onClick={()=>{setActiveIndex(1)}} style={{width:100,marginLeft:20}}>SELL</Button>
                            </div>:
                            <div>
                                <Button onClick={()=>{setActiveIndex(0)}} style={{width:100}}>BUY</Button>
                                <Button onClick={()=>{setActiveIndex(1)}} style={{width:100,background:'#eee',color:'#000',marginLeft:20}}>SELL</Button>
                            </div>
                        }
                    </div>
                    <div style={{marginBottom:20}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>{activeIndex==0?'USDT':'OPL'} Amount (balance:{activeIndex==0?usdtBalance.toString():oplBalance.toString()})</div>
                        <InputWithButton value={inputValue}
                                         onChange={setInputValue}
                                         maxValue={activeIndex==0?usdtBalance:oplBalance} width="100%"/>
                    </div>
                    <div style={{marginBottom:10}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>
                            You will receive
                        </div>
                        <div style={{width:'100%',height:60,display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div className="finance-amount">{inputValue} {activeIndex==0?'OPL':'USDT'}</div>
                        </div>
                    </div>
                    <Button onClick={buyOPL} style={{marginTop:10}}>Swap</Button>
                </div>
            </div>

            <div className="finance-card-log">
                <div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>Financial Statements</div>
                <div style={{marginTop:20,paddingTop:20}}>
                    {logs.map((item, index) => (
                        <div style={{marginBottom:40,paddingLeft:20,paddingRight:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',fontSize:16,color:'#000'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <img src={pdfIcon} style={{width:30,marginRight:30}}/>
                                <div>{item.name}</div>
                            </div>
                            <ButtonFlat style={{width:100}}>View</ButtonFlat>
                        </div>
                    ))}
                </div>
            </div>

      </div>
    </div>
  );
};

export default Finance;
