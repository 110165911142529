export const INVITER_ADDR = "INVITER_ADDR";

export const SELL_MANAGER ='0x5e0c4e880912e08256c960a2bd9c149603db7f36'
export const USDT_TOKEN ='0x55d398326f99059fF775485246999027B3197955'

export const OPL_TOKEN = '0x1e0c041E0cDe66671AFba68c5a0Db1A49ab3CcCC'

export const OPE_TOKEN = '0xEcC48D7dCEa1D4ac311DB9A7DAC5662A57a56100'

export const OPASS_NFT = '0x265E5B07c29D322aB2883E0E577f1337F7030D71'

export const OPX_NFT = '0xb2b01177145D230BE2E8eEc2cC8174964Fc93083'

export const OPT_NFT = '0xcd1d2Cbd9FB2C6B339e1823D6e2e9da6e7f05c58'


export const PRIMARY_COLOR = '#0DB836';
