import React from "react";
import Button from "../Button/Button";

interface InputWithButtonProps {
    width?: string;
    maxValue?: number;
    onChange?: (value: string) => void;
    value?: string;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
                                                             width = "300px",
                                                             maxValue,
                                                             onChange,
                                                             value,
                                                         }) => {
    // 验证输入是否为有效的非负数（包括小数）
    const isValidNumber = (value: string): boolean => {
        // 允许空字符串
        if (value === "") return true;
        // 允许单个小数点
        if (value === ".") return true;
        // 检查是否为非负数（包括小数）
        const regex = /^\d*\.?\d*$/;
        return regex.test(value);
    };

    // 处理输入框值变化
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        // 只有当输入是有效的数字格式时才更新值
        if (isValidNumber(newValue)) {
            if (onChange) {
                onChange(newValue);
            }
        }
    };

    // 处理失去焦点时的格式化
    const handleBlur = () => {
        if (value === "" || value === ".") {
            onChange?.("");
            return;
        }

        // 格式化数字，去除末尾的小数点和不必要的0
        const formattedValue = Number(value).toString();
        if (onChange && formattedValue !== value) {
            onChange(formattedValue);
        }
    };

    // 处理点击最大值按钮
    const handleMaxClick = () => {
        if (maxValue !== undefined && onChange) {
            onChange(maxValue.toString());
        }
    };

    return (
        <div style={{ ...styles.container, width }}>
            <input
                type="text"
                value={value}
                onChange={handleInputChange}
                onBlur={handleBlur}
                placeholder=""
                style={styles.input}
            />
            <Button
                style={styles.button}
                onClick={handleMaxClick}
            >
                Max
            </Button>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "5px",
    },
    input: {
        flex: 1,
        border: "none",
        outline: "none",
        padding: "8px",
        borderRadius: "8px 0 0 8px",
        fontSize: "16px",
    },
    button: {
        marginLeft: "8px",
        padding: "8px 12px",
        border: "none",
        borderRadius: "8px",
        backgroundColor: "#007bff",
        color: "#fff",
        cursor: "pointer",
        width: '150px'
    },
};

export default InputWithButton;